import React, { useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import IndividualPageLayoutTemplate from '../components/IndividualPageLayoutTemplate'

import '../css/global.scss'


export const WorkItemTemplate = ({
  pageName,
  bannerImg,
  bannerTitle,
  bannerDescription,
  introSectionTitle,
  introSectionDescription,
  topicList,
  spotlightActivity,
  helmet,
  client
  }) => {

 



  return (
    <>
      {/* {helmet || ''} */}
      <IndividualPageLayoutTemplate 
          pageName={pageName}
          bannerImg={bannerImg}
          bannerTitle={bannerTitle}
          bannerDescription={bannerDescription}
          introSectionTitle={introSectionTitle}
          introSectionDescription={introSectionDescription}
          spotlightActivity={spotlightActivity}
          topicList={topicList}
          client={client}
          // technologies={technologies}
        />
    </>
  )
}

WorkItemTemplate.propTypes = {
//   description: PropTypes.string,
  pageName: PropTypes.string,
  client: PropTypes.string,
  industry: PropTypes.string,
  technologies: PropTypes.array,
  bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bannerTitle: PropTypes.string,
  bannerDescription: PropTypes.string,
  introSectionTitle: PropTypes.string,
  introSectionDescription: PropTypes.string,
  topicList: PropTypes.string,
  spotlightActivity: PropTypes.array,
  helmet: PropTypes.object,
}

const Work = ({ data }) => {

  const { frontmatter } = data.markdownRemark

  return (
    <Layout
        title={frontmatter.bannerTitle}
        description={frontmatter.bannerDescription}
    >
      <WorkItemTemplate
        client={frontmatter.client}
        industry={frontmatter.industry}
        technologies={frontmatter.technologies}
        bannerImg={frontmatter.bannerImg}
        bannerTitle={frontmatter.bannerTitle}
        bannerDescription={frontmatter.bannerDescription}
        introSectionTitle={frontmatter.introSectionTitle}
        introSectionDescription={frontmatter.introSectionDescription}
        topicList={frontmatter.topicList}
        // spotlightActivity={frontmatter.spotlightActivity}
        pageName={frontmatter.pageName}
      />
    </Layout>
  )
}

Work.propTypes = {
    data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Work

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        pageName
        client
        industry
        technologies
        bannerImg {
          extension
          publicURL
        }
        bannerTitle
        bannerDescription
        introSectionTitle
        introSectionDescription
        topicList {
          thumbnail {
            extension
            publicURL
          }
          title
          description
        }
        spotlightActivity {
          title
          link
          description
          thumbnail {
              extension
              publicURL
            }
        }
      }
    }
  }
`
